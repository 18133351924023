import {TariffToggleStore, useTariffToggleStoreMemo} from "citizens/components/tariff_toggle";
import {BanCitizenStore, useBanCitizenStoreMemo} from "citizens/modals/ban_citizen";
import {ChangeCitizenTypeStore, useChangeCitizenTypeStoreMemo} from "citizens/modals/change_citizen_type";
import {DeleteAccessStore, useDeleteAccessStoreMemo} from "citizens/modals/delete_access";
import {DeleteCitizenStore, useDeleteCitizenStoreMemo} from "citizens/modals/delete_citizen";
import {CitizenAccessRepository, CitizenRepository} from "citizens/repositories";
import {CitizenBalanceRepository} from "citizens/repositories/CitizenBalanceRepository";
import {Citizen, CitizenAccess, CitizenAccessType} from "citizens/types";
import {CitizenBalance} from "citizens/types/CitizenBalance";
import {useRepositories} from "common/components/app/RepositoriesStore";
import {AsyncJob} from "common/stores/job";
import {Pageable} from "common/types";
import {createContextAndHook} from "common/utils";
import {makeAutoObservable, runInAction} from "mobx";
import {useMemo} from "react";
import {UserConstraints} from "users/validation";
import {CitizenSubscriptionStore, useCitizenSubscriptionStoreMemo} from "./CitizenSubscriptionStore";

export class CitizenUserStore {
    user: Citizen | null;
    isUserDeleted: boolean;
    private canLoadMoreBalance: boolean;
    private canLoadMoreAccess: boolean;
    isBalanceLoading: boolean;
    isAddressesLoading: boolean;

    balanceHistory: CitizenBalance[];
    balanceHistoryPage: number;
    balanceHistoryMaxPage: number;

    accesses: CitizenAccess[];
    accessesPage: number;
    accessesMaxPage: number;

    private readonly updateEmailJob: AsyncJob<typeof CitizenUserStore.prototype._updateEmail>;
    private readonly updateSecondNameJob: AsyncJob<typeof CitizenUserStore.prototype._updateSecondName>;
    private readonly updateFirstNameJob: AsyncJob<typeof CitizenUserStore.prototype._updateFirstName>;
    private readonly updateMiddleNameJob: AsyncJob<typeof CitizenUserStore.prototype._updateMiddleName>;
    private readonly updatePhoneJob: AsyncJob<typeof CitizenUserStore.prototype._updatePhone>;
    private readonly fetchJob: AsyncJob<typeof CitizenUserStore.prototype._fetch>;
    private readonly fetchBalanceJob: AsyncJob<typeof CitizenUserStore.prototype._fetchBalanceHistory>;
    private readonly fetchAccessesJob: AsyncJob<typeof CitizenUserStore.prototype._fetchAccesses>;

    constructor(
        private readonly citizenRepo: CitizenRepository,
        private readonly accessRepo: CitizenAccessRepository,
        private readonly balanceRepo: CitizenBalanceRepository,
        readonly typeChangeStore: ChangeCitizenTypeStore,
        readonly banModal: BanCitizenStore,
        readonly deleteAccessModal: DeleteAccessStore,
        readonly deleteUserModal: DeleteCitizenStore,
        readonly tariffToggleStore: TariffToggleStore,
        readonly subscription: CitizenSubscriptionStore,
    ) {
        this.user = null;
        this.balanceHistory = [];
        this.balanceHistoryPage = 0;
        this.balanceHistoryMaxPage = 0;
        this.accesses = [];
        this.accessesPage = 0;
        this.accessesMaxPage = 0;
        this.isUserDeleted = false;
        this.canLoadMoreBalance = true;
        this.canLoadMoreAccess = true;
        this.isBalanceLoading = true
        this.isAddressesLoading = true;
        makeAutoObservable(this, {}, {autoBind: true});
        this.typeChangeStore.setSaveListener(() => {
            runInAction(() => this.fetchAccessesJob.startQuietly(true))
        });
        this.updateEmailJob = new AsyncJob({job: this._updateEmail});
        this.updateFirstNameJob = new AsyncJob({job: this._updateFirstName});
        this.updateSecondNameJob = new AsyncJob({job: this._updateSecondName});
        this.updateMiddleNameJob = new AsyncJob({job: this._updateMiddleName});
        this.updatePhoneJob = new AsyncJob({job: this._updatePhone});
        this.fetchJob = new AsyncJob({job: this._fetch});
        this.fetchBalanceJob = new AsyncJob({job: this._fetchBalanceHistory});
        this.fetchAccessesJob = new AsyncJob({job: this._fetchAccesses});
        this.tariffToggleStore.setChangeListener(() => {
            runInAction(() => this.fetchAccessesJob.startQuietly(true))
        });
    }

    get isLoading() {
        return this.fetchJob.isCreated || this.fetchJob.isPending || this.deleteUserModal.isLoading || this.isUserDeleted;
    }

    get modalError() {
        return this.updateEmailJob.errorMessage
            || this.updatePhoneJob.errorMessage
            || this.updateFirstNameJob.errorMessage
            || this.updateSecondNameJob.errorMessage
            || this.updateMiddleNameJob.errorMessage
            || this.banModal.errorMessage
            || this.deleteUserModal.errorMessage
            || this.deleteAccessModal.errorMessage
            || this.tariffToggleStore.modalError;
    }

    get balanceError() {
        return this.fetchBalanceJob.errorMessage;
    }

    get addressesError() {
        return this.fetchAccessesJob.errorMessage;
    }

    get errorMessage() {
        return this.fetchJob.errorMessage;
    }

    updateEmail(newValue: string) {
        const n = newValue.trim();
        if (this.user?.email === n) {
            return;
        }

        if (n.length > 0) {
            if (!n.includes(" ") && n.includes("@")) {
                this.updateEmailJob.start(n);
            }
        } else {
            this.updateEmailJob.start("");
        }
    }

    updateFirstName(newValue: string) {
        const n = newValue.trim();
        if (this.user?.firstName !== n
            && n.length >= UserConstraints.firstNameMinLength
            && n.length <= UserConstraints.firstNameMaxLength) {
            this.updateFirstNameJob.start(n);
        }
    }

    updateSecondName(newValue: string) {
        const n = newValue.trim();
        if (this.user?.secondName !== n
            && n.length >= UserConstraints.secondNameMinLength
            && n.length <= UserConstraints.secondNameMaxLength) {
            this.updateSecondNameJob.start(n);
        }
    }

    updateMiddleName(newValue: string) {
        const n = newValue.trim();
        if (this.user?.middleName !== n && n.length <= UserConstraints.middleNameMaxLength) {
            this.updateMiddleNameJob.start(n);
        }
    }

    updatePhone(newValue: string) {
        if (this.user?.phone !== newValue) {
            this.updatePhoneJob.start(newValue);
        }
    }

    updateType(access: CitizenAccess, newType: CitizenAccessType) {
        if (this.user !== null) {
            this.typeChangeStore.show({
                newType: newType,
                accessId: access.id,
                citizenId: this.user.id,
                currentType: access.type,
            });
        }
    }

    openDeleteModal() {
        if (this.user != null) {
            this.deleteUserModal.show(this.user);
        }
    }

    openDeleteAccessModal(access: CitizenAccess) {
        if (this.user) {
            this.deleteAccessModal.show({
                id: access.id,
                citizenName: this.user.fullName,
                address: access.address,
                apartment: access.apartment,
            });
        }
    }

    dismissModalError() {
        this.banModal.dismissError();
        this.deleteUserModal.dismissError();
        this.deleteAccessModal.dismissError();
        this.updateEmailJob.clearError();
        this.updateFirstNameJob.clearError();
        this.updateSecondNameJob.clearError();
        this.updateMiddleNameJob.clearError();
        this.updatePhoneJob.clearError();
        this.tariffToggleStore.dismissModalError();
    }

    loadMoreBalance() {
        if (!this.fetchBalanceJob.isPending && this.canLoadMoreBalance) {
            this.fetchBalanceJob.start();
        }
    }

    loadMoreAccesses() {
        if (!this.fetchAccessesJob.isPending && this.canLoadMoreAccess) {
            this.fetchAccessesJob.start(false);
        }
    }

    loadIfNeed(citizenId: string) {
        this.banModal.setBanListener(this.updateQuietly);
        this.deleteAccessModal.setDeleteListener(this.updateQuietly);
        this.deleteUserModal.setDeleteListener(() => runInAction(() => {
            this.isUserDeleted = true;
        }));
        this.fetchJob.start(citizenId, false);
    }

    unload() {
        this.banModal.setBanListener(null);
        this.deleteAccessModal.setDeleteListener(null);
        this.deleteUserModal.setDeleteListener(null);
    }

    private updateQuietly() {
        const userId = this.user?.id;
        if (userId) {
            this.fetchJob.startQuietly(userId, true);
        }
    }

    private* _updateEmail(_: AbortSignal, newValue: string) {
        const user = this.user;
        if (user == null) throw new Error("Illegal state: user is null")

        this.user = {...this.user!!, email: newValue};
        yield this.citizenRepo.putById(user.id, {email: newValue});
    }

    private* _updateSecondName(_: AbortSignal, newValue: string) {
        const user = this.user;
        if (user == null) throw new Error("Illegal state: user is null")

        this.user = {...this.user!!, secondName: newValue};
        yield this.citizenRepo.putById(user.id, {secondName: newValue});
    }

    private* _updateFirstName(_: AbortSignal, newValue: string) {
        const user = this.user;
        if (user == null) throw new Error("Illegal state: user is null")

        this.user = {...this.user!!, firstName: newValue};
        yield this.citizenRepo.putById(user.id, {firstName: newValue});
    }

    private* _updateMiddleName(_: AbortSignal, newValue: string) {
        const user = this.user;
        if (user == null) throw new Error("Illegal state: user is null")

        this.user = {...this.user!!, middleName: newValue};
        yield this.citizenRepo.putById(user.id, {middleName: newValue});
    }

    private* _updatePhone(_: AbortSignal, newValue: string) {
        const user = this.user;
        if (user == null) throw new Error("Illegal state: user is null")

        this.user = {...this.user!!, phone: newValue};
        yield this.citizenRepo.putById(user.id, {phone: newValue});
    }

    private* _fetch(signal: AbortSignal, citizenId: string, isOnlyUser: boolean) {
        this.user = yield this.citizenRepo.findById(citizenId, signal);
        this.fetchAccessesJob.start(false);
        if (!isOnlyUser) {
            this.fetchBalanceJob.start();
            this.subscription.startFetch();
        }
    }

    private* _fetchBalanceHistory(_: AbortSignal) {
        const citizenId = this.user?.id;
        if (!citizenId) {
            throw new Error("Illegal state: citizenId is null");
        }

        const response: Pageable<CitizenBalance> = yield this.balanceRepo.findAll({
            limit: 30,
            page: this.balanceHistoryPage,
            citizenId: citizenId,
        });
        if (this.balanceHistoryPage === 0) {
            this.balanceHistory = response.items;
        } else {
            this.balanceHistory = [...this.balanceHistory, ...response.items];
        }
        this.balanceHistoryMaxPage = response.maxPage;
        this.canLoadMoreBalance = this.balanceHistoryPage < this.balanceHistoryMaxPage - 1;
        this.balanceHistoryPage += 1;
        this.isBalanceLoading = false
    }

    private* _fetchAccesses(signal: AbortSignal, isRefreshAll: boolean) {
        const citizenId = this.user?.id;
        if (!citizenId) {
            throw new Error("Illegal state: citizenId is null");
        }

        if (isRefreshAll) {
            const response: Pageable<CitizenAccess> = yield this.accessRepo.findAllAccesses({
                citizenId,
                limit: this.accesses.length,
                page: 0,
            }, signal);
            this.accesses = response.items;
        } else {
            const response: Pageable<CitizenAccess> = yield this.accessRepo.findAllAccesses({
                citizenId,
                limit: 30,
                page: this.accessesPage,
            }, signal);
            if (this.accessesPage === 0) {
                this.accesses = response.items;
            } else {
                this.accesses = [...this.accesses, ...response.items];
            }
            this.accessesMaxPage = response.maxPage;
        }

        this.canLoadMoreAccess = this.accessesPage < this.accessesMaxPage - 1;
        this.accessesPage += 1;
        this.isAddressesLoading = false;
    }
}

export function useCitizenUserStoreMemo(citizenId: string) {
    const repos = useRepositories();
    const typeChangeStore = useChangeCitizenTypeStoreMemo();
    const banModal = useBanCitizenStoreMemo();
    const deleteAccessModal = useDeleteAccessStoreMemo();
    const deleteCitizenStore = useDeleteCitizenStoreMemo();
    const tariffToggleStore = useTariffToggleStoreMemo();
    const subscriptionStore = useCitizenSubscriptionStoreMemo(citizenId)
    return useMemo(() => new CitizenUserStore(
        repos.citizen,
        repos.citizenAccess,
        repos.balanceHistory,
        typeChangeStore,
        banModal,
        deleteAccessModal,
        deleteCitizenStore,
        tariffToggleStore,
        subscriptionStore
    ), [repos, typeChangeStore, banModal, deleteAccessModal, deleteCitizenStore, tariffToggleStore]);
}

const [CitizenUserStoreContext, useCitizenUserStore] = createContextAndHook<CitizenUserStore>();
export {CitizenUserStoreContext, useCitizenUserStore};
