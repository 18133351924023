export type CompanyTariffType = "citizen" | "service"

export interface CompanyTariff {
    name: string;
    description: string;
    price: number;
    priceOfUnit: number;
    id: number;
    isPopular: boolean;
    isActivate: boolean;
    isCalculationHint: boolean;
    opportunities: CompanyTariffDescription[];
    backgroundColor: string
}

export interface CompanyTariffDescription {
    text: string;
}

export interface CompanyTariffResponse {
    name: string;
    description: string;
    price: number;
    price_unit: number;
    id: number;
    is_popular: boolean;
    is_active: boolean;
    is_calculation_hint: boolean;
    opportunities: CompanyTariffDescription[];
    bg_color: string
}


export function mapToTariff(response: CompanyTariffResponse): CompanyTariff {
    return {
        name: response.name,
        description: response.description,
        price: response.price,
        priceOfUnit: response.price_unit,
        id: response.id,
        isPopular: response.is_popular,
        isActivate: response.is_active,
        isCalculationHint: response.is_calculation_hint,
        opportunities: response.opportunities,
        backgroundColor: response.bg_color
    };
}