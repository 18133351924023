import createSvgIcon from "./utils";

const IconComputer = createSvgIcon((
    <>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.9444 33.3619C16.4228 33.3619 16 33.7848 16 34.3064V34.3619H11.9444C11.4228 34.3619 11 34.7848 11 35.3064V36.4175C11 36.9391 11.4228 37.3619 11.9444 37.3619H28.0556C28.5772 37.3619 29 36.9391 29 36.4175V35.3064C29 34.7848 28.5772 34.3619 28.0556 34.3619H24V34.3064C24 33.7848 23.5772 33.3619 23.0556 33.3619H16.9444Z"
              stroke="transparent"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3 8.13972C3 6.05331 4.69137 4.36194 6.77778 4.36194H33.2222C35.3086 4.36194 37 6.05331 37 8.13972V27.5842C37 29.6706 35.3086 31.3619 33.2222 31.3619H6.77778C4.69137 31.3619 3 29.6706 3 27.5842V8.13972ZM7 11.1953C7 9.63046 8.26853 8.36194 9.83333 8.36194H30.1667C31.7315 8.36194 33 9.63047 33 11.1953V22.5286C33 24.0934 31.7315 25.3619 30.1667 25.3619H9.83333C8.26853 25.3619 7 24.0934 7 22.5286V11.1953ZM32.9444 29.2508C33.466 29.2508 33.8889 28.828 33.8889 28.3064C33.8889 27.7848 33.466 27.3619 32.9444 27.3619C32.4228 27.3619 32 27.7848 32 28.3064C32 28.828 32.4228 29.2508 32.9444 29.2508Z"
              stroke="transparent"/>
    </>
), {width: 40, height: 40});

export default IconComputer;