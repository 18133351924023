export interface CitizenSubscription {
    sum: number | null;
    status: "active" | "disabled";
    date: Date;
}

export interface CitizenSubscriptionResponse {
    subscription_sum: number | null;
    subscription_status: "active" | "disabled";
    date: number;
}

export type FindAllSubscriptionHistoryParams = {
    citizenId: string;
    limit: number;
    page: number;
};

export function mapToCitizenSubscriptionHistory(response: CitizenSubscriptionResponse): CitizenSubscription {
    return {
        sum: response.subscription_sum,
        status: response.subscription_status,
        date: new Date(response.date * 1000),
    }
}