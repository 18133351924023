import {CitizenSubscriptionRepository} from "citizens/repositories";
import {Citizen, CitizenSubscription} from "citizens/types";
import {useRepositories} from "common/components/app/RepositoriesStore";
import {AsyncJob} from "common/stores/job";
import {makeAutoObservable} from "mobx";
import {useMemo} from "react";
import {Pageable} from "../../../common/types";

export class CitizenSubscriptionStore {
    user: Citizen | null;
    history: CitizenSubscription[];
    page: number;
    maxPage: number;
    private canLoadMore: boolean;
    isLoading: boolean;

    private readonly fetchJob: AsyncJob<typeof CitizenSubscriptionStore.prototype._fetchSubscriptionHistory>;

    constructor(
        private readonly subscriptionRepo: CitizenSubscriptionRepository,
        private readonly citizenId: string
    ) {
        this.user = null;
        this.history = [];
        this.page = 0;
        this.canLoadMore = true;
        this.maxPage = 0;
        this.isLoading = true;
        makeAutoObservable(this, {}, {autoBind: true});
        this.fetchJob = new AsyncJob({job: this._fetchSubscriptionHistory});
    }

    get errorMessage() {
        return this.fetchJob.errorMessage;
    }

    startFetch() {
        this.fetchJob.start();
    }

    loadMore() {
        if (!this.fetchJob.isPending && this.canLoadMore) {
            this.fetchJob.start();
        }
    }
    private* _fetchSubscriptionHistory(_: AbortSignal) {
        const response: Pageable<CitizenSubscription> = yield this.subscriptionRepo.findAll({
            limit: 30,
            page: this.page,
            citizenId: this.citizenId,
        });
        if (this.page === 0) {
            this.history = response.items;
        } else {
            this.history = [...this.history, ...response.items];
        }
        this.maxPage = response.maxPage;
        this.canLoadMore = this.page < this.maxPage - 1;
        this.page += 1;
        this.isLoading = false
    }
}

export function useCitizenSubscriptionStoreMemo(citizenId: string) {
    const repos = useRepositories();
    return useMemo(() => new CitizenSubscriptionStore(
        repos.subscription,
        citizenId
    ), [repos, citizenId]);
}

