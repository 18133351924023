import classNames from "classnames";
import {DetailedHTMLProps, TableHTMLAttributes} from "react";
import s from "./Table.module.css";

type Props = {
    variant?: TableVariant;
    isHighlightedRows?: boolean;
} & DetailedHTMLProps<TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>;

export type TableVariant = "default" | "modal" | "embed" | "normal-header";

const classByVariant: Record<TableVariant, string> = {
    "embed": s.variantEmbed,
    "default": s.variantDefault,
    "normal-header": s.variantNormalHeader,
    "modal": s.variantModal,
};

export const Table = (props: Props) => {
    const {className, variant = "default", isHighlightedRows = true, ...defaultProps} = props;
    return (
        <table className={classNames(
            s.table,
            classByVariant[variant],
            isHighlightedRows && s.highlight,
            className,
        )} {...defaultProps} />
    );
}