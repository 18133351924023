import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";
import {FindAllRealtimeUser, mapToRealtimeUser, RealtimeUser, RealtimeUserResponse} from "../types";

export class RealtimeUserRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(param: FindAllRealtimeUser): Promise<Pageable<RealtimeUser>> {
        const response = await this.client.get<PageableResponse<RealtimeUserResponse>>(
            `web/v1/task-monitoring/`, {
                signal: param.signal,
                isUseQueryArrays: true,
                params: {
                    page: param.page + 1,
                    limit: param.limit,
                    users_ids: param.userIds,
                    is_online: param.isOnline,
                },
            }
        );

        return mapPageable(response, mapToRealtimeUser);
    }
}
