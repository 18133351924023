import {BanCitizenModal} from "citizens/modals/ban_citizen";
import {ChangeCitizenTypeModal} from "citizens/modals/change_citizen_type";
import {DeleteAccessModal} from "citizens/modals/delete_access";
import {DeleteCitizenModal} from "citizens/modals/delete_citizen";
import {CitizenUserNavigation} from "citizens/routes/citizen_user/CitizenUserNavigation";
import {CitizenUserStoreContext, useCitizenUserStoreMemo} from "citizens/routes/citizen_user/CitizenUserStore";
import {
    accessesColumns,
    addressSkeleton,
    balanceColumns,
    balanceSkeleton,
    infoColumns,
    infoColumnsSkeleton,
    subscriptionColumns,
    subscriptionSkeleton
} from "citizens/routes/citizen_user/columns";
import {ContentBlock} from "citizens/routes/citizen_user/ContentBlock";
import AutoTable from "common/components/auto_table";
import TableSkeleton from "common/components/auto_table/skeleton";
import {ErrorModal} from "common/components/modal/error";
import {Route} from "common/components/route";
import {observer} from "mobx-react-lite";
import React, {useEffect, useMemo} from "react";
import {useNavigate, useParams} from "react-router-dom";
import s from "./CitizenUserRoute.module.css";

function useCitizenId() {
    const id = useParams<{ id: string; }>().id;
    if (!id) {
        throw new Error("Illegal state: id is null")
    }
    return id;
}

export const CitizenUserRoute = observer(() => {
    const citizenId = useCitizenId();
    const store = useCitizenUserStoreMemo(citizenId);


    useEffect(() => {
        store.loadIfNeed(citizenId);
        return () => store.unload();
    }, [store, citizenId]);

    const navigate = useNavigate();
    useEffect(() => {
        if (store.isUserDeleted) {
            navigate("/citizen?citizen_tab=users", {replace: true})
        }
    }, [navigate, store.isUserDeleted]);

    const user = store.user;
    const infoTableData = useMemo(() => {
        if (user) {
            return [user];
        } else return [];
    }, [user]);


    return (
        <CitizenUserStoreContext.Provider value={store}>
            <Route
                title="ЕТД Житель"
                error={store.errorMessage}
                navigation={<CitizenUserNavigation/>}
                isLoading={store.isLoading}>
                <div className={s.content}>
                    <ContentBlock className={s.info} title="Житель">
                        {store.isLoading && (
                            <TableSkeleton
                                amountOfColumns={infoColumns.length}
                                amountOfRows={1}
                                columns={infoColumnsSkeleton}
                                variant="embed"/>
                        )}

                        {!store.isLoading && (
                            <AutoTable
                                columns={infoColumns}
                                data={infoTableData}
                                variant="embed"
                                highlightable={false}
                                isStickyHeader={false}
                                isNoElementsEnabled={false}/>
                        )}
                    </ContentBlock>

                    <ContentBlock
                        className={s.addresses}
                        title="Адреса"
                        error={store.addressesError}>
                        <div className={s.table} onScrollCapture={event => {
                            const target = event.target as HTMLElement;
                            if (target.scrollHeight - target.getBoundingClientRect().height - target.scrollTop <= 40) {
                                store.loadMoreAccesses();
                            }
                        }}>
                            {store.isAddressesLoading && (
                                <TableSkeleton
                                    amountOfColumns={accessesColumns.length}
                                    columns={addressSkeleton}
                                    variant="embed"/>
                            )}
                            {!store.isAddressesLoading && (
                                <AutoTable
                                    columns={accessesColumns}
                                    data={store.accesses}
                                    variant="embed"/>
                            )}
                        </div>
                    </ContentBlock>

                    <ContentBlock className={s.tasks} title="Заявки" isPlaceholder></ContentBlock>

                    <ContentBlock
                        className={s.balanceHistory}
                        title={`Баланс: ${user?.balance || 0} руб. (${user?.bonuses || 0} руб.)`}
                        error={store.balanceError}>
                        <div className={s.table} onScrollCapture={event => {
                            const target = event.target as HTMLElement;
                            if (target.scrollHeight - target.getBoundingClientRect().height - target.scrollTop <= 40) {
                                store.loadMoreBalance();
                            }
                        }}>
                            {store.isBalanceLoading && (
                                <TableSkeleton
                                    amountOfColumns={balanceColumns.length}
                                    columns={balanceSkeleton}
                                    variant="embed"/>
                            )}
                            {!store.isBalanceLoading && (
                                <AutoTable
                                    columns={balanceColumns}
                                    data={store.balanceHistory}
                                    variant="embed"/>
                            )}
                        </div>
                    </ContentBlock>

                    <ContentBlock
                        className={s.subscriptionHistory}
                        title={`Автосписание: ${user?.subscriptionSum || 0} руб.`}
                        error={store.subscription.errorMessage}>
                        <div className={s.table} onScrollCapture={event => {
                            const target = event.target as HTMLElement;
                            if (target.scrollHeight - target.getBoundingClientRect().height - target.scrollTop <= 20) {
                                store.subscription.loadMore();
                            }
                        }}>
                            {store.subscription.isLoading && (
                                <TableSkeleton
                                    amountOfColumns={subscriptionColumns.length}
                                    columns={subscriptionSkeleton}
                                    variant="embed"/>
                            )}
                            {!store.subscription.isLoading && (
                                <AutoTable
                                    columns={subscriptionColumns}
                                    data={store.subscription.history}
                                    variant="embed"/>
                            )}
                        </div>
                    </ContentBlock>
                </div>
            </Route>

            <BanCitizenModal store={store.banModal}/>
            <DeleteCitizenModal store={store.deleteUserModal}/>
            <DeleteAccessModal store={store.deleteAccessModal}/>
            <ChangeCitizenTypeModal store={store.typeChangeStore}/>
            <ErrorModal error={store.modalError} dismiss={store.dismissModalError}/>
        </CitizenUserStoreContext.Provider>
    );
});
