import {AuthorizedHttpClient} from "common/net";
import {
    CursorPageableResponse,
    IEntityId,
    mapCursorPageable,
    mapPageable,
    Pageable,
    PageableResponse
} from "common/types";
import {FindReportSliceParams, mapReportSliceParamsToBody} from "reports/types";
import {FindScheduledTaskFilterParam} from "tasks/types";
import {
    CommonHouse,
    FindAllHousesParams,
    FindAllListedHousesParams,
    House,
    HouseAddress,
    HouseCreateParams,
    HousePatchParams,
    HouseResponse,
    ListedHouse,
    ListedHouseResponse,
    mapToHouse,
    mapToListedHouse
} from "../types";

export class NewHouseRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    create(companyId: number, params: HouseCreateParams): Promise<void> {
        return this.client.post(
            `v1/companies/${companyId}/houses`, {
                body: {
                    apartment_count: params.apartmentCount,
                    floor_count: params.floorCount,
                    entrance_count: params.entranceCount,
                    geo: params.geoLat && params.geoLon ? {
                        lat: params.geoLat,
                        lon: params.geoLon,
                    } : null,
                    address: params.address,
                    description: params.description,
                    systems: params.systems,
                    tags: params.tags,
                    fias_id: params.fiasId,
                }
            }
        );
    }

    createFromAddress(
        companyId: number,
        address: string,
        lat: string,
        lon: string,
    ): Promise<IEntityId> {
        return this.client.post(
            `v1/companies/${companyId}/houses/task-induced`, {
                body: {address, lat, lon}
            }
        );
    }

    patch(id: number, companyId: number, params: HousePatchParams): Promise<void> {
        return this.client.patch(
            `v1/companies/${companyId}/houses/${id}`, {
                signal: params.signal,
                body: {
                    apartment_count: params.apartmentCount,
                    floor_count: params.floorCount,
                    entrance_count: params.entranceCount,
                    geo: params.geo === undefined ? undefined : params.geo,
                    description: params.description,
                    systems: params.systems,
                    tags: params.tags,
                    is_verified: params.isVerified,
                }
            }
        );
    }

    patchAddress(id: number, address: string, fiasId: string | null): Promise<void> {
        return this.client.patch(
            `v1/houses/${id}/address`, {
                body: {address, fias_id: fiasId}
            }
        );
    }

    async findById(id: number): Promise<House> {
        const response = await this.client.get<HouseResponse>(`v2/houses/${id}`);
        return mapToHouse(response);
    }

    async findByCompanyId(id: number, companyId: number): Promise<CommonHouse> {
        const response = await this.client.get<HouseResponse>(`v2/companies/${companyId}/houses/${id}`);
        return mapToHouse(response);
    }

    async findAll(params: FindAllHousesParams): Promise<Pageable<House>> {
        const response = await this.client.get<PageableResponse<HouseResponse>>(
            `v2/houses/`, {
                params: {
                    page: params.page,
                    limit: params.limit || 10,
                    search: params.search,
                    tags: params.tags?.join(","),
                    systems: params.systems?.join(","),
                    companies: params.companies?.join(","),
                    verified_status: params.verifiedStatus || undefined,
                },
                signal: params.signal,
            },
        );

        return mapPageable(response, mapToHouse);
    }

    /**
     * Запрос на получение списка объектов, несущих минимальную информацию о домах.
     *
     * В отличие от полноценного {@link findAll} выполняется намного быстрее за счет отсутствия время затратной для
     * формирования на сервере информации (тегов, систем и тд).
     *
     * Опциональное поле {@link params.companyId} определяет дома какой компании необходимо вернуть. В случае отсутствия
     * данного поля будут отданы дома компании, которой принадлежит авторизированный пользователь. Если пользователь имеет
     * роль <i>Диспетчер ЕТД</i>, метод возвращает дома всех компаний, к которым пользователю выдан доступ.
     *
     * Для группы компаний возвращает дома дочерних компаний.
     */
    async findAllListed(params: FindAllListedHousesParams): Promise<Pageable<ListedHouse>> {
        const response = await this.client.get<PageableResponse<ListedHouseResponse>>(
            `/v1/tasks/filters/addresses`, {
                params: {
                    page: params.page,
                    limit: params.limit,
                    search: params.search,
                    company_id: params.companyId || undefined,
                },
                signal: params.signal,
            }
        );

        return mapPageable(response, mapToListedHouse);
    }

    async findAllForScheduledTaskFilter(param: FindScheduledTaskFilterParam) {
        const response = await this.client.get<CursorPageableResponse<ListedHouseResponse>>(
            "web/v1/scheduled-tasks/filters", {
                params: {
                    type_filter: "house",
                    cursor: param.cursor || undefined,
                    limit: param.limit,
                    search: param.search.trim() || undefined,
                    company_id: param.companyId || undefined,
                },
            });
        return mapCursorPageable(response, mapToListedHouse);
    }

    async findDocumentFilters(params: FindAllListedHousesParams): Promise<Pageable<ListedHouse>> {
        const response = await this.client.get<PageableResponse<ListedHouseResponse>>(
            `web/v1/tasks/documents/filters/houses`, {
                params: {
                    page: params.page,
                    limit: params.limit || 25,
                    search: params.search || undefined,
                    company_id: params.companyId || undefined,
                },
                signal: params.signal,
            }
        );
        return mapPageable(response, mapToListedHouse);
    }

    async findAllByCompanyId(companyId: number, params: Omit<FindAllHousesParams, "has_citizen">): Promise<Pageable<House>> {
        const response = await this.client.get<PageableResponse<HouseResponse>>(
            `v2/companies/${companyId}/houses`, {
                signal: params.signal,
                params: {
                    page: params.page,
                    limit: params.limit || 10,
                    search: params.search,
                    tags: params.tags?.join(","),
                    systems: params.systems?.join(","),
                    companies: params.companies?.join(","),
                    verified_status: params.verifiedStatus || undefined,
                }
            }
        );

        return mapPageable(response, mapToHouse);
    }

    async findAllBySlices(params: FindReportSliceParams): Promise<Pageable<HouseAddress>> {
        const response = await this.client.post<PageableResponse<HouseAddress>>("web/v1/reports/addresses", {
            body: mapReportSliceParamsToBody(params),
            signal: params.signal,
        });
        return mapPageable(response, item => item);
    }

    async deleteById(id: number): Promise<void> {
        return await this.client.delete(`v1/houses/${id}`);
    }

    createCompanies(id: number, companies: number[]): Promise<void> {
        return this.client.put(`v1/houses/${id}/companies`, {
            body: {companies},
        });
    }

    deleteCompany(id: number, companyId: number): Promise<void> {
        return this.client.delete(`v1/houses/${id}/companies/${companyId} `);
    }
}
