import {CalendarEventType} from "./CalendarUser";

export interface RealtimeUserResponse {
    id: number;
    first_name: string;
    last_name: string;
    patronymic: string;
    avatar_url: string;
    position: string;
    is_online: boolean;
    overdue_count: number;
    count: number;
    badges?: {
        text: string;
        type: CalendarEventType;
    }[];
    statuses_stat?: {
        id: number;
        name: string;
        count: string;
        color: string;
    }[];
}

export interface RealtimeUser {
    id: number;
    fullName: string;
    avatarUrl: string;
    isOnline: boolean;
    position: string;
    overdueCount: number;
    count: number;
    badges: {
        text: string;
        type: CalendarEventType;
    }[];
    statuses: {
        id: number;
        name: string;
        count: string;
        color: string;
    }[];
}

export interface FindAllRealtimeUser {
    page: number;
    limit: number;
    isOnline: boolean;
    signal: AbortSignal;
    userIds: number[];
}

export function mapToRealtimeUser(response: RealtimeUserResponse): RealtimeUser {
    return {
        id: response.id,
        fullName: `${response.last_name} ${response.first_name} ${response.patronymic}`.trim(),
        avatarUrl: response.avatar_url,
        overdueCount: response.overdue_count,
        count: response.count,
        position: response.position,
        isOnline: response.is_online || false,
        badges: response.badges || [],
        statuses: response.statuses_stat || [],
    };
}