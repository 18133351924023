//From: https://gist.github.com/jfsiii/5641126
export function luminanceRgb(r: number, g: number, b: number): number {
    const RsRGB = r / 255;
    const GsRGB = g / 255;
    const BsRGB = b / 255;

    const R = (RsRGB <= 0.03928) ? RsRGB / 12.92 : Math.pow((RsRGB + 0.055) / 1.055, 2.4);
    const G = (GsRGB <= 0.03928) ? GsRGB / 12.92 : Math.pow((GsRGB + 0.055) / 1.055, 2.4);
    const B = (BsRGB <= 0.03928) ? BsRGB / 12.92 : Math.pow((BsRGB + 0.055) / 1.055, 2.4);

    return 0.2126 * R + 0.7152 * G + 0.0722 * B;
}

export function hexToRgb(hex: string): { r: number, g: number, b: number } {
    if (hex.startsWith("#")) {
        hex = hex.substring(1);
    }

    const bigint = parseInt(hex.substring(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return {r, g, b};
}

export function luminanceHex(hex: string): number {
    const rgb = hexToRgb(hex);
    return luminanceRgb(rgb.r, rgb.g, rgb.b);
}

export function isDarkHex(hex: string): boolean {
    return luminanceHex(hex) < 0.1;
}
