export interface UdginFlatRange {
    from: number;
    to: number;
    offset: number;
    prefix: string;
}

export interface UdginFlatRangeResponse {
    first_flat_number: number;
    last_flat_number: number;
    offset: number;
    prefix: string;
}

export interface UdginSpecialFlat {
    flat: string;
    internalNumber: string;
}

export interface UdginSpecialFlatResponse {
    flat_number: string,
    internal_flat_number: string
}

export type FindAllIntercoms = {
    page: number;
    limit: number;
    search: string;
    signal?: AbortSignal;
};

export function mapToFlatRange(model: UdginFlatRangeResponse): UdginFlatRange {
    return {
        from: model.first_flat_number,
        to: model.last_flat_number,
        offset: model.offset,
        prefix: model.prefix,
    };
}

export function mapToSpecialFlat(model: UdginSpecialFlatResponse): UdginSpecialFlat {
    return {
        flat: model.flat_number,
        internalNumber: model.internal_flat_number,
    };
}

export type IntercomVendor = "udgin" | "basip" | "sputnik";
export const AllIntercomVendors: IntercomVendor[] = ["udgin", "basip", "sputnik"];

interface BaseIntercom {
    id: string;
    name: string;
    vendor: IntercomVendor;
    warning: string | null;
    videoName: string;
    embedLink: string;
    sipNumber: string;
    sipPassword: string;
    groups: { id: string, name: string }[];
    description: string;
}

export type UdginIntercomState = "work" | "test";

export interface UdginIntercom extends BaseIntercom {
    vendor: "udgin";
    identifier: string;
    camera: {
        ip: string;
        port: number;
        streamName: string;
    };
    login: string;
    password: string;
    deviceType: string;
    server: {
        ip: string;
        sshPort: number;
        logPort: number;
    };
    flatRanges: UdginFlatRange[];
    specialFlats: UdginSpecialFlat[];
    micVolume: number;
    soundVolume: number;
    enableTestFlat: boolean;
    sipDispatcher: string | null;
    testFlatNumber: string;
    state: UdginIntercomState;
}

export interface BasipIntercom extends BaseIntercom {
    vendor: "basip";
    apiHost: string;
    apiPort: string;
    username: string;
    password: string;
    brightnessLevel: number;
    speakerVolume: number;
}

export interface SputnikIntercom extends BaseIntercom {
    vendor: "sputnik";
    number: string;
    username: string;
    password: string;
    micSensitivity: number;
    speakerVolume: number;
    sipVolume: number;
    generalVolume: number;
}

export type AnyIntercom = BasipIntercom | UdginIntercom | SputnikIntercom;

export interface UpsertBaseIntercom {
    name: string;
    vendor: IntercomVendor;
    videoName: string;
    sipNumber: string;
    sipPassword: string;
    groupIds: string[];
    description: string;
}

export interface UpsertBasipIntercom extends UpsertBaseIntercom {
    vendor: "basip";
    apiHost: string;
    apiPort: string;
    username: string;
    password: string;
    brightnessLevel: number;
    speakerVolume: number;
}

export interface UpsertSputnikIntercom extends UpsertBaseIntercom {
    vendor: "sputnik";
    number: string;
    username: string;
    password: string;
    micSensitivity: number;
    speakerVolume: number;
    sipVolume: number;
    generalVolume: number;
}

export interface UpsertUdginIntercom extends UpsertBaseIntercom {
    vendor: "udgin";
    identifier: string;
    camera: {
        ip: string;
        port: number;
        streamName: string;
    };
    login: string;
    password: string;
    deviceType: string;
    server: {
        ip: string;
        sshPort: number;
        logPort: number;
    };
    micVolume: number;
    soundVolume: number;
    state: UdginIntercomState;
}

export type UpsertAnyIntercom = UpsertBasipIntercom | UpsertUdginIntercom | UpsertSputnikIntercom;

export interface UdginForwardsResponse {
    flat_ranges: UdginFlatRangeResponse[];
    special_flats: UdginSpecialFlatResponse[];
    sip_dispatcher: string | null;
    enable_test_flat: boolean;
    test_flat_number: string;
}

export interface UdginForwards {
    flatRanges: UdginFlatRange[];
    specialFlats: UdginSpecialFlat[];
    sipDispatcher: string | null;
    enableTestFlat: boolean;
    testFlatNumber: string;
}

export type UpdateUdginForwards = UdginForwards

export interface IntercomResponse {
    id: string;
    warning: string | null;
    default_name: string;
    video_name: string;
    embed_url: string;
    sip_number: string;
    sip_password: string;
    groups: { id: string, name: string }[];
    description: string;
    basip: {
        api_host: string,
        api_port: string,
        username: string,
        password: string,
        brightness_level: number,
        speaker_volume: number,
    } | null;
    sputnik: {
        number: string;
        rtsp_username: string,
        rtsp_password: string,
        mic_sensitivity: string,
        speaker_volume: string,
        sip_volume: string,
        general_volume: string,
    } | null;
    udgin: {
        auth_device_name: string;
        camera: {
            ip: string;
            port: number;
            stream_name: string;
        };
        login: string;
        password: string;
        device_type: string;
        server_ip: string,
        server_log_port: number,
        server_ssh_port: number,
        sound_volume: number;
        microphone_volume: number;
        flat_ranges: UdginFlatRangeResponse[];
        special_flats: UdginSpecialFlatResponse[];
        enable_test_flat: boolean;
        test_flat_number: string;
        sip_dispatcher: string;
        state: UdginIntercomState;
    };
}

export function mapToUdginForwards(model: UdginForwardsResponse): UdginForwards {
    return {
        enableTestFlat: model.enable_test_flat,
        specialFlats: model.special_flats.map(mapToSpecialFlat),
        flatRanges: model.flat_ranges.map(mapToFlatRange),
        sipDispatcher: model.sip_dispatcher,
        testFlatNumber: model.test_flat_number,
    };
}

export function mapUpsertParamToBody(model: UpsertAnyIntercom): object {
    return {
        vendor: model.vendor,
        default_name: model.name,
        video_name: model.videoName,
        sip_number: model.sipNumber,
        sip_password: model.sipPassword,
        groups: model.groupIds,
        description: model.description,
        basip: model.vendor === "basip" ? {
            api_host: model.apiHost || "",
            api_port: model.apiPort || "",
            password: model.password || "",
            username: model.username || "",
            brightness_level: model.brightnessLevel,
            speaker_volume: model.speakerVolume,
        } : null,
        sputnik: model.vendor === "sputnik" ? {
            number: model.number || "",
            api_host: "https://api.sputnik.systems/query",
            rtsp_password: model.password || "",
            rtsp_username: model.username || "",
            mic_sensitivity: model.micSensitivity.toString(),
            speaker_volume: model.speakerVolume.toString(),
            sip_volume: model.sipVolume.toString(),
            general_volume: model.generalVolume.toString(),
        } : null,
        udgin: model.vendor === "udgin" ? {
            auth_device_name: model.identifier,
            camera: {
                ip: model.camera.ip,
                port: model.camera.port,
                stream_name: model.camera.streamName,
            },
            login: model.login,
            password: model.password,
            device_type: model.deviceType,
            server_ip: model.server.ip,
            server_log_port: model.server.logPort,
            server_ssh_port: model.server.sshPort,
            sound_volume: model.soundVolume,
            microphone_volume: model.micVolume,
            state: model.state,
        } : null,
    };
}

export function mapToIntercom(model: IntercomResponse): AnyIntercom {
    const base: Omit<BaseIntercom, "vendor"> = {
        id: model.id,
        name: model.default_name,
        warning: model.warning || null,
        videoName: model.video_name || "",
        embedLink: model.embed_url || "",
        sipNumber: model.sip_number || "",
        sipPassword: model.sip_password || "",
        groups: model.groups || [],
        description: model.description,
    };

    if (model.basip) {
        return {
            ...base,
            vendor: "basip",
            apiHost: model.basip.api_host || "",
            apiPort: model.basip.api_port || "",
            password: model.basip.password || "",
            username: model.basip.username || "",
            brightnessLevel: model.basip.brightness_level || 0,
            speakerVolume: model.basip.speaker_volume || 0,
        }
    }
    if (model.sputnik) {
        return {
            ...base,
            vendor: "sputnik",
            number: model.sputnik.number || "",
            password: model.sputnik.rtsp_password || "",
            username: model.sputnik.rtsp_username || "",
            generalVolume: Number(model.sputnik.general_volume) || 0,
            sipVolume: Number(model.sputnik.sip_volume) || 0,
            micSensitivity: Number(model.sputnik.mic_sensitivity) || 0,
            speakerVolume: Number(model.sputnik.speaker_volume) || 0,
        }
    } else if (model.udgin) {
        return {
            ...base,
            vendor: "udgin",
            login: model.udgin.login || "",
            password: model.udgin.password || "",
            deviceType: model.udgin.device_type || "udgin",
            soundVolume: model.udgin.sound_volume,
            micVolume: model.udgin.microphone_volume,
            identifier: model.udgin.auth_device_name,
            camera: {
                ip: model.udgin.camera.ip || "",
                port: model.udgin.camera.port || 0,
                streamName: model.udgin.camera.stream_name || "",
            },
            server: {
                ip: model.udgin.server_ip || "",
                logPort: model.udgin.server_log_port || 0,
                sshPort: model.udgin.server_ssh_port || 0,
            },
            flatRanges: model.udgin?.flat_ranges ? model.udgin.flat_ranges.map(mapToFlatRange) : [],
            specialFlats: model.udgin?.special_flats ? model.udgin.special_flats.map(mapToSpecialFlat) : [],
            enableTestFlat: model.udgin?.enable_test_flat || false,
            sipDispatcher: model.udgin?.sip_dispatcher || null,
            state: model.udgin?.state || "work",
            testFlatNumber: model.udgin?.test_flat_number || "",
        };
    }

    throw new Error("Unknown intercom vendor");
}
