import {AuthorizedHttpClient} from "../../common/net";
import {mapPageable, Pageable, PageableResponse} from "../../common/types";
import {
    CitizenSubscription,
    CitizenSubscriptionResponse,
    FindAllSubscriptionHistoryParams,
    mapToCitizenSubscriptionHistory
} from "../types";

export class CitizenSubscriptionRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(params: FindAllSubscriptionHistoryParams): Promise<Pageable<CitizenSubscription>> {
        const response = await this.client.get<PageableResponse<CitizenSubscriptionResponse>>(
            `/citizens/${params.citizenId}/subscription-history`, {
                params: {
                    page: params.page + 1,
                    limit: params.limit,
                },
            });
        return mapPageable(response, mapToCitizenSubscriptionHistory);
    }
}