import * as Sentry from "@sentry/react";
import "react-datepicker/dist/react-datepicker.css";
import "reset-css";
import "./common/index.css";
import "./common/yup_locale";
import {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import App from "./common/components/app";
import {MixpanelProvider} from 'react-mixpanel-browser';
import type {Config as MixPanelConfig} from "mixpanel-browser";

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://898e5a41443e48d41c3c7f598959b279@o4505878265462784.ingest.sentry.io/4505900606423040",
        integrations: [
            new Sentry.Replay(),
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        environment: process.env.REACT_APP_ENV,
    });
}

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
const MIXPANEL_CONFIG: Partial<MixPanelConfig> = {
    track_pageview: false,
};

const root = createRoot(document.getElementById("root")!);
root.render(
    <StrictMode>
        <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}>
            <App/>
        </MixpanelProvider>
    </StrictMode>
);
