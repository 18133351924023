import {AuthorizedHttpClient} from "../../common/net";

import {CompanyTariff, CompanyTariffResponse, CompanyTariffType, mapToTariff} from "../types";

export class CompanyTariffsRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(type: CompanyTariffType, signal: AbortSignal): Promise<CompanyTariff[]> {
        const response = await this.client.get<CompanyTariffResponse[]>(
            "/web/v1/companies/tariffs",
            {
                params: {type},
                signal
            }
        );
        return response.map(mapToTariff);
    }
}