import {HouseSystem, HouseSystemResponse, mapToHouseSystem} from "./HouseSystem";
import {HouseTag, HouseTagResponse, mapToHouseTag} from "./HouseTag";

export interface CommonHouse {
    id: number;

    address: string;
}

export interface HouseAttachedCompany {
    id: number;
    name: string;
}

export interface House {
    id: number;

    address: string;

    description: string;

    apartmentCount: number;

    floorCount: number;

    entranceCount: number;

    fiasId: string;

    geo: { lat: string, lon: string } | null;

    tags: HouseTag[];

    companies: HouseAttachedCompany[];

    systems: HouseSystem[];

    isVerified: boolean;
}

export interface HouseAddress {
    id: number;
    address: string;
}

/**
 * Минимальная информация о доме. Полный объект - {@link House}.
 *
 * Используется для оптимизации поиска по домам на страницах заявок, благодаря отсутствию время затратных полей для формирования
 * на сервере (тегов, систем и тд).
 */
export interface ListedHouse {
    id: number;

    address: string;

    companyId: number;
}

export interface HouseCreateParams {
    apartmentCount: number;
    floorCount: number;
    entranceCount: number;
    geoLat: string | null;
    geoLon: string | null;
    address: string;
    description: string;
    systems: number[];
    tags: number[];
    fiasId: string | null;
}

export interface HousePatchParams {
    apartmentCount?: number;
    floorCount?: number;
    entranceCount?: number;
    geo?: { lat: string, lon: string } | null;
    description?: string;
    systems?: number[];
    tags?: number[];
    isVerified?: boolean;
    signal?: AbortSignal;
}

export interface HouseResponse {
    id: number;

    address: string;

    description: string;

    apartment_count: number;

    floor_count: number;

    entrance_count: number;

    fias_id: string;

    geo: { lat: string, lon: string } | null;

    tags: HouseTagResponse[];

    systems: HouseSystemResponse[];

    companies: { id: number, name: string }[];

    is_verified?: boolean;
}

export interface ListedHouseResponse {
    id: number;

    address: string;

    company_id: number;
}

export function mapToHouse(entity: HouseResponse): House {
    return {
        id: entity.id,
        companies: entity.companies,
        tags: entity.tags.map(tag => mapToHouseTag(tag)),
        address: entity.address,
        systems: entity.systems.map(mapToHouseSystem),
        geo: entity.geo,
        description: entity.description,
        entranceCount: entity.entrance_count,
        floorCount: entity.floor_count,
        fiasId: entity.fias_id,
        apartmentCount: entity.apartment_count,
        isVerified: entity.is_verified || false,
    };
}

export type FindAllGrantedHouseParams = {
    page: number;
    limit: number;
    search?: string;
    systems?: number[];
    tags?: number[];
};

export type PatchGrantedHouseParams = {
    systems: {
        grant: number[],
        revoke: number[],
    };
    houses: {
        grant: Record<number, number[]>,  //{system_id: [house_id1, ...], ...}
        revoke: Record<number, number[]>, //{system_id: [house_id1, ...], ...}
    };
};

export type FindAllHouseSystemsParam = {
    signal?: AbortSignal;
};

export type FindAllListedHouseSystemsParam = {
    page: number;
    limit: number;
    search?: string;
    companyId: number | null;
    signal?: AbortSignal;
};

export type FindAllListedHousesParams = {
    page?: number;
    limit?: number;
    search: string;
    companyId: number | null;
    signal?: AbortSignal;
};

export type FindAllHousesParams = {
    search?: string;
    limit?: number;
    page: number;
    tags?: number[];
    systems?: number[];
    companies?: number[];
    verifiedStatus?: "verified" | "not-verified";
    signal?: AbortSignal;
};

export function mapToListedHouse(entity: ListedHouseResponse): ListedHouse {
    return {
        id: entity.id,
        address: entity.address,
        companyId: entity.company_id,
    };
}
