export interface Pageable<T> {
    maxPage: number;
    count?: number | null;
    items: T[];
}

export interface CursorPageable<T> {
    nextCursor: number;
    items: T[];
}

export interface PageableResponse<T> {
    max_page: number;
    count?: number | null;
    items: T[];
}

export interface CursorPageableResponse<T> {
    next_cursor: number;
    items: T[];
}

export interface CitizenPageableResponse<T> {
    pages: number;
    items: T[];
}

export function mapPageable<T, R>(entity: PageableResponse<T>, mapper: (entity: T) => R): Pageable<R> {
    return {
        maxPage: entity.max_page,
        count: entity.count ?? undefined,
        items: entity.items.map(mapper),
    };
}

export function mapCursorPageable<T, R>(entity: CursorPageableResponse<T>, mapper: (entity: T) => R): CursorPageable<R> {
    return {
        nextCursor: entity.next_cursor,
        items: entity.items.map(mapper),
    };
}

export function mapCitizenPageable<T, R>(entity: CitizenPageableResponse<T>, mapper: (entity: T) => R): Pageable<R> {
    return {
        maxPage: entity.pages,
        count: null,
        items: entity.items.map(mapper),
    };
}